/* RESET & BASIC STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  --white: #fff;
  --black: #333;
  --darkblue: #1b4965;
  --lightblue: #edf2f4;
  --table-border: #dde7ea;
}

.container {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}
/* TABLE STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.r-main-tbl table {
  text-align: left;
  border-collapse: collapse;
}

.order-tbl th {
  color: var(--white);
  background: var(--darkblue);
  padding: 5px 15px;
}
.reward-text-green{
  color: #129712;
  font-weight: 900;
}
.r-main-tbl table td {
  width: 100%;
  padding: 10px;
}

.r-main-tbl table td:first-child {
  position: relative;
  padding-right: 60px;
}

.r-main-tbl table td:first-child::before {
  display: none;
  content: "";
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--secondColor);
}

.r-main-tbl table td:nth-child(2) {
  position: relative;
}

.r-main-tbl table tbody tr {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  counter-increment: counter;
}

.r-main-tbl table tbody tr::before {
  content: counter(counter);
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
  color: var(--white);
  background: var(--black);
  z-index: 1;
}

.r-main-tbl table tbody tr:nth-of-type(even)>* {
  background: var(--lightblue);
}

.r-main-tbl table img {
  text-align: center;
  justify-content: center;
  max-width: 100px;
  height: 100px;
}

.table-credits {
  font-size: 12px;
}

/* MQ STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media (max-width:468px) {
  .r-main-tbl table tbody tr::before {
    display: none;
    top: 0px;
    right: 5px;
    width: 25px;
    line-height: 25px;
  }

  .r-main-tbl table td {
    padding: 3px;
    font-size: 11px;
  }

  .btn-mbl-text {
    font-size: 10px;
  }
}

@media (max-width: 999px) {
  .visible\@l {
    display: none;
  }
}

@media (min-width: 700px) {
  /* .r-main-tbl table td {
    padding: 20px;
  } */
}

@media (min-width: 700px) and (max-width: 999px) {
  .r-main-tbl table tbody {
    display: flex;
    flex-wrap: wrap;
  }

  .r-main-tbl table tbody tr {
    width: 50%;
  }

  .r-main-tbl table tbody tr:nth-of-type(even)>* {
    background: transparent;
  }

  .r-main-tbl table tbody tr:nth-of-type(4n)>*,
  .r-main-tbl table tbody tr:nth-of-type(4n + 1)>* {
    background: var(--lightblue);
  }
}

@media (min-width: 1000px) {
  .hidden\@l {
    display: none;
  }

  .container {
    padding: 0 30px;
  }

  /* table {
    border: none;
  } */

  .r-main-tbl table th,
  .r-main-tbl table td {
    width: 25%;
  }

  .r-main-tbl table td:first-child {
    padding-right: 0;
  }

  .r-main-tbl table tbody tr {
    display: table-row;
  }

  .r-main-tbl table tbody tr::before {
    display: none;
  }
}

@media (hover: hover) and (min-width: 1000px) {
  .r-main-tbl table tbody tr:hover {
    cursor: pointer;
  }

  .r-main-tbl table tbody tr:hover img {
    display: block;
  }

  .r-main-tbl table tbody tr:hover td:first-child::before {
    display: block;
  }
}

@media (min-width: 1250px) {
  .r-main-tbl table td:first-child::before {
    right: calc(100% + 20px);
  }
}

/* FOOTER STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  position: fixed;
  right: 0;
  bottom: 50px;
  display: flex;
  align-items: center;
  padding: 5px;
  z-index: 1;
  background: var(--white);
}

.page-footer a {
  display: flex;
  margin-left: 4px;
}